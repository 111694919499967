import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    color: #757575;
    font-size: 16px;
`;

const Index = () => {
    return (
        <Wrapper>
            <h2>Website under maintenance! </h2>
        </Wrapper>
    )
}

export default Index
 